var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summarize-post-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$t(_vm.module.model.pageMiniHelpMessageKey)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('a-card',[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_daily_digest',
          'disabled': _vm.disabled,
          'hasAnchor': true,
          'customMiniHelpMessage': _vm.$t('field_channel_enable_daily_digest_mini_help_message')
        }
      }}}),(_vm.module.model.params.enable_daily_digest)?_c('nested-content',[_c('time-picker-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'daily_digest_time',
            'clearable': false,
            'time24hr': true,
            'disabled': _vm.disabled,
            'hasAnchor': true
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'daily_digest_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'disabled': _vm.disabled
          },
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_weekly_digest',
          'disabled': _vm.disabled,
          'hasAnchor': true
        }
      }}}),(_vm.module.model.params.enable_weekly_digest)?_c('nested-content',[_c('config-field',{attrs:{"title":_vm.$t('field_weekly_digest_send_to_channel_title'),"has-anchor":"","inline":"","field-key":"weekly_digest_send_to_channel"}},[_c('toggle-button',{attrs:{"disabled":_vm.disabled,"unchecked-title":_vm.$t('digest_send_to_group'),"checked-title":_vm.$t('digest_send_to_channel')},model:{value:(_vm.module.model.params.weekly_digest_send_to_channel),callback:function ($$v) {_vm.$set(_vm.module.model.params, "weekly_digest_send_to_channel", $$v)},expression:"module.model.params.weekly_digest_send_to_channel"}})],1),_c('time-picker-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'weekly_time',
            'clearable': false,
            'time24hr': true,
            'disabled': _vm.disabled,
            'hasAnchor': true
          }
        }}}),_c('week-day-picker-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'day_of_week',
            'disabled': _vm.disabled,
            'hasAnchor': true
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'weekly_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'disabled': _vm.disabled
          },
        }}})],1):_vm._e()],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'enable_monthly_digest',
          'disabled': _vm.disabled,
          'hasAnchor': true
        }
      }}}),(_vm.module.model.params.enable_monthly_digest)?_c('nested-content',[_c('config-field',{attrs:{"title":_vm.$t('field_monthly_digest_send_to_channel_title'),"has-anchor":"","inline":"","field-key":"monthly_digest_send_to_channel"}},[_c('toggle-button',{attrs:{"disabled":_vm.disabled,"unchecked-title":_vm.$t('digest_send_to_group'),"checked-title":_vm.$t('digest_send_to_channel')},model:{value:(_vm.module.model.params.monthly_digest_send_to_channel),callback:function ($$v) {_vm.$set(_vm.module.model.params, "monthly_digest_send_to_channel", $$v)},expression:"module.model.params.monthly_digest_send_to_channel"}})],1),_c('time-picker-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'monthly_time',
            'clearable': false,
            'time24hr': true,
            'disabled': _vm.disabled,
            'hasAnchor': true
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'day_of_month',
            'min': 1,
            'max': 31,
            'disabled': _vm.disabled,
            'hasAnchor': true
          },
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'monthly_message',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'hasAnchor': true,
            'disabled': _vm.disabled
          },
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }